<template>
  <h3 class="grey-color">タイピングバラエティ</h3>
  <v-btn
    v-for="(select, i) in selects"
    :key="i"
    color="#ffff99"
    class="select-btn grey-color"
    cols="6"
    width="100%"
    no-gutters
    :href="select.url"
    >{{ select.title }}</v-btn
  >
  <!-- 広告 -->
  <v-row justify="center">
    <v-col cols="12" class="text-center">
      <a
        href="https://px.a8.net/svt/ejp?a8mat=3NPIDJ+9IC9MA+4GDM+601S1"
        rel="nofollow"
      >
        <img
          class="margin scale-img"
          border="0"
          width="360"
          height="60"
          alt=""
          src="https://www22.a8.net/svt/bgt?aid=221219911575&wid=001&eno=01&mid=s00000020785001008000&mc=1"
      /></a>
      <img
        border="0"
        width="1"
        height="1"
        src="https://www15.a8.net/0.gif?a8mat=3NPIDJ+9IC9MA+4GDM+601S1"
        alt=""
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    selects: [
      {
        title: "韓国語タイピング技能検定",
        logo: "",
        url: "https://kr-typing.com",
      },
      {
        title: "仕事",
        logo: "",
        url: "/game-select/work",
      },
      {
        title: "生活",
        logo: "",
        url: "/game-select/life",
      },
      {
        title: "恋愛",
        logo: "",
        url: "/game-select/love",
      },
      {
        title: "トリリンガルのトミ韓国講座",
        logo: "",
        url: "/game-select/TrilingualTomi",
      },
      // {
      //   title: "ランキング",
      //   logo: "",
      //   url: "/ranking",
      // },
      // {
      //   title: "ドラマ",
      //   logo: "",
      //   url: "/game-select/drama",
      // },
      // {
      //   title: "K-POP",
      //   logo: "",
      //   url: "/game-select/kpop",
      // },
    ],
  }),
  // mounted() {
  //   // スマホサイズの判定
  //   if (window.innerWidth <= 767) {
  //     // 韓国語タイピング技能検定を削除
  //     this.selects = this.selects.filter(
  //       (select) => select.title !== "韓国語タイピング技能検定"
  //     );
  //   }
  // },
};
</script>

<style scoped>
.margin.scale-img {
  width: 100%; /* 画像の幅を親要素に合わせる */
  height: auto; /* 高さを自動調整 */
}
.select-btn {
  margin-top: 12px;
}
p {
  margin: 0 auto;
}
.margin {
  margin: 12px auto;
}
.scale-img {
  width: 350px; /*画像の幅*/
  height: 60px; /*画像の高さ*/
  transition-duration: 0.5s; /*変化の時間*/
}
.scale-img:hover {
  transform: scale(1.1, 1.1); /*画像の拡大*/
  cursor: pointer; /*カーソルをポインターにする*/
}
</style>
