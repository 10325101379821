<template>
  <div class="pa-4 text-center">
    <v-dialog max-width="800" v-model="dialogVisible">
      <!-- <template v-slot:activator="{ props: activatorProps }">
        <v-btn v-bind="activatorProps" text="Open Dialog"></v-btn>
      </template> -->

      <!-- <template v-slot:default="{ isActive }"> -->
      <v-card class="w-full grey-color" title="">
        <template v-slot:text>
          <v-card-title class="card-title rounded"
            ><v-icon
              icon="mdi-check-circle"
            />韓国語タイピング技能検定のご紹介</v-card-title
          >

          <div class="mb-10">
            <h3 class="mb-2">
              <v-icon
                icon="mdi-check"
              />完全無料で30テーマ以上の問題が練習できる！
            </h3>
            <p class="mb-2">
              (無料)新規登録すると30テーマ以上の問題が無料で練習できます！
              <br />
              問題は随時更新！
            </p>
            <a
              href="https://kr-typing.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                :src="require('@/assets/exam_introduction_1.png')"
                style="width: 100%"
                class="rounded-md border"
              />
            </a>
          </div>

          <div class="mb-10">
            <h3 class="mb-2">
              <v-icon icon="mdi-check" />最新のスコア判定基準！
            </h3>
            最新のスコア評価基準でより正確で実践的な判定ができます！
            <br />
            <div style="overflow-x: auto">
              <v-table class="border rounded my-2" style="min-width: 600px">
                <thead>
                  <tr class="" style="background-color: #54b3fc; color: white">
                    <th class="text-left">検定級</th>
                    <th class="text-left">レベル</th>
                    <th class="text-left">スコア</th>
                    <th class="text-left">できること</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1級</td>
                    <td>プロフェッショナル</td>
                    <td>501~</td>
                    <td>書き込みや議事録作成ができる</td>
                  </tr>
                  <tr>
                    <td>2級</td>
                    <td>上級レベル</td>
                    <td>401~500</td>
                    <td>高速で正確な入力ができ、ビジネス文書の作成ができる</td>
                  </tr>
                  <tr>
                    <td>3級</td>
                    <td>実務レベル</td>
                    <td>301~400</td>
                    <td>ビジネスメールやニュース記事を打てる</td>
                  </tr>
                  <tr>
                    <td>4級</td>
                    <td>初級レベル</td>
                    <td>151~300</td>
                    <td>短い文章をスムーズに入力できる</td>
                  </tr>
                  <tr>
                    <td>5級</td>
                    <td>基礎レベル</td>
                    <td>51~150</td>
                    <td>簡単な短文を正しく入力できる</td>
                  </tr>
                  <tr>
                    <td>6級</td>
                    <td>入門レベル</td>
                    <td>~50</td>
                    <td>ハングルの単語をゆっくり入力できる</td>
                  </tr>
                </tbody>
              </v-table>
            </div>
            ※スコア算出方法の開示やスコアに関する異議申し立てはお受けできませんのでご了承ください。
          </div>

          <div class="mb-10">
            <h3 class="mb-2">
              <v-icon icon="mdi-check" />スキルの証明が可能！
            </h3>
            韓国語タイピング技能検定では、あなたのタイピングスキルを正確に評価しそれを見える形にすることで面接時などでアピールしやすいようになっています。
            <br />
            検定に合格することで、公式の認定証が発行し、あなたのスキルを客観的に証明する手段として活用できます。
            <br />
            この認定証は、履歴書やポートフォリオに記載することができるため、就職や転職活動においても有利です！
            <img
              :src="require('@/assets/exam_introduction_2.png')"
              style="width: 100%"
              class="rounded-md border my-2 sp-only"
            />
          </div>

          <v-btn
            href="https://kr-typing.com"
            class="text-center start-btn mb-5"
            color="#ffff99"
            height="70"
            width="100%"
          >
            韓国語タイピング技能検定へ！<v-icon icon="mdi-arrow-right" />
          </v-btn>
        </template>
      </v-card>
      <!-- </template> -->
    </v-dialog>
  </div>
</template>

<script>
// import { mdiAccount } from "@mdi/js";
export default {
  name: "ExamDialog",
  data() {
    return {
      dialogVisible: false,
    };
  },
  mounted() {
    // localStorageからviewCountを取得
    let viewCount = parseInt(localStorage.getItem("viewCount")) || 0;
    // 初回訪問時にダイアログを表示
    if (viewCount === 0) this.dialogVisible = true;
    // viewCountを更新
    viewCount += 1;
    // viewCountが10になったらリセット
    if (viewCount >= 10) viewCount = 0;
    // 更新したviewCountをlocalStorageに保存
    localStorage.setItem("viewCount", viewCount);
  },
};
</script>

<style scoped>
.text-blue {
  color: #54b3fc;
}

.sp-only {
  display: none;
}

@media (max-width: 767px) {
  .sp-only {
    display: block;
    width: 100%;
  }
}
</style>
